import { ChangeEventHandler } from 'react'
import './TextField.css'

export default function TextField(props: {
  label: string
  value: string
  onChange: ChangeEventHandler<any>
  error?: string
  onBlur?: () => void
}) {
  return (
    <div className='input-group'>
      {props.label === 'Address' || props.label === 'Remark'
        ? <textarea 
            aria-label={props.label} 
            value={props.value} 
            onChange={props.onChange}
            onBlur={props.onBlur}
            rows={4} 
            maxLength={512} 
            required 
            aria-invalid={!!props.error}
            aria-describedby={props.error ? `${props.label.toLowerCase()}-error` : undefined}
          ></textarea>
        : <input 
            type='text' 
            aria-label={props.label} 
            value={props.value} 
            onChange={props.onChange} 
            onBlur={props.onBlur}
            required 
            aria-invalid={!!props.error}
            aria-describedby={props.error ? `${props.label.toLowerCase()}-error` : undefined}
          />
      }
      <span className='highlight'></span>
      <span className='bar'></span>
      <label> {props.label} </label>
      {props.error && <div 
        className="error-message" 
        id={`${props.label.toLowerCase()}-error`}
      >
        {props.error}
      </div>}
    </div>
  )
}

function DropDownField(props: {
  label: string
  values: Array<string>
  onChange: ChangeEventHandler<any>
  error?: string
  onBlur?: () => void
}) {
  return (
    <div className='input-group dropdown-group'>
      <p className='dropdown-label'>{props.label}</p>
      <select
        onChange={props.onChange}
        onBlur={props.onBlur}
        aria-invalid={!!props.error}
        aria-describedby={props.error ? `${props.label.toLowerCase()}-error` : undefined}
        aria-label={props.label}
      >
        {props.values.map(v =>
          <option key={v} value={v}>{v}</option>
        )}
      </select>
      {props.error && 
        <div 
          className="error-message" 
          id={`${props.label.toLowerCase()}-error`}
        >
          {props.error}
        </div>
      }
    </div>
  )
}

export { DropDownField }